
import { defineComponent, ref } from "vue";
import PageHeader from "@/components/PageHeader.vue";
import PageContent from "@/components/PageContent.vue";
import SkillBar from "@/components/SkillBar.vue";
import ExperienceItem from "@/components/ExperienceItem.vue";
import { isMobile, myAge } from "@/utils";
import { formatDistanceToNowStrict } from "date-fns";

interface Social {
  title: string;
  link: string;
  iconName: [string, string];
  color: string;
  show: boolean;
  isHovered: boolean;
}

interface Skill {
  name: string;
  knwl: number;
}

interface Experience {
  name: string;
  logo: string;
  role: string;
  description: string;
  join: string;
  leave: string | null;
}

export default defineComponent({
  name: "AboutMe",
  components: { PageHeader, PageContent, SkillBar, ExperienceItem },
  setup() {
    const socials = ref<Social[]>([
      {
        title: "Facebook",
        link: "https://facebook.com/vasconcelos.arthur",
        iconName: ["fab", "facebook-f"],
        color: "#3B579D",
        show: false,
        isHovered: false
      },
      {
        title: "Twitter",
        link: "https://twitter.com/jookeringa",
        iconName: ["fab", "twitter"],
        color: "#00ACEE",
        show: false,
        isHovered: false
      },
      {
        title: "LinkedIn",
        link: "https://linkedin.com/in/arthurvasconcelos/",
        iconName: ["fab", "linkedin-in"],
        color: "#0A75B5",
        show: true,
        isHovered: false
      },
      {
        title: "Github",
        link: "https://github.com/arthurvasconcelos",
        iconName: ["fab", "github-alt"],
        color: "#171515",
        show: true,
        isHovered: false
      },
      {
        title: "Bitbucket",
        link: "https://bitbucket.org/arthurvasconcelos/",
        iconName: ["fab", "bitbucket"],
        color: "#2684FF",
        show: false,
        isHovered: false
      },
      {
        title: "Codepen",
        link: "https://codepen.io/arthurvasconcelos/",
        iconName: ["fab", "codepen"],
        color: "#303C42",
        show: true,
        isHovered: false
      },
      {
        title: "JSFiddle",
        link: "https://jsfiddle.net/user/arthurvasconcelos",
        iconName: ["fab", "jsfiddle"],
        color: "#4679A4",
        show: false,
        isHovered: false
      },
      {
        title: "NPM",
        link: "https://www.npmjs.com/~arthurvasconcelos",
        iconName: ["fab", "npm"],
        color: "#CB3837",
        show: true,
        isHovered: false
      },
      {
        title: "Keybase",
        link: "https://keybase.io/avasconcelos",
        iconName: ["fab", "keybase"],
        color: "#FF6F21",
        show: true,
        isHovered: false
      },
      {
        title: "Stack Overflow",
        link: "https://stackoverflow.com/users/3130385/arthur-vasconcelos",
        iconName: ["fab", "stack-overflow"],
        color: "#F48024",
        show: true,
        isHovered: false
      },
      {
        title: "Twitch",
        link: "https://www.twitch.tv/jokeringa",
        iconName: ["fab", "twitch"],
        color: "#6441A4",
        show: false,
        isHovered: false
      },
      {
        title: "Reddit",
        link: "https://www.reddit.com/user/jookeringa",
        iconName: ["fab", "reddit-alien"],
        color: "#FF4500",
        show: false,
        isHovered: false
      }
      // {
      //     title: "#",
      //     link: "#",
      //     iconName: ["fab", "github-alt"],
      //     color: "#f00",
      //     show: false,
      //     isHovered: false
      // }
    ]);
    const skills = ref<Skill[]>([
      {
        name: "HTML",
        knwl: 100
      },
      {
        name: "CSS",
        knwl: 100
      },
      {
        name: "SASS",
        knwl: 80
      },
      {
        name: "LESS",
        knwl: 60
      },
      {
        name: "Javascript",
        knwl: 100
      },
      {
        name: "VueJS",
        knwl: 85
      },
      {
        name: "Angular",
        knwl: 65
      },
      {
        name: "EmberJS",
        knwl: 75
      },
      {
        name: "PHP",
        knwl: 80
      },
      {
        name: "Laravel",
        knwl: 70
      },
      {
        name: "Symfony",
        knwl: 45
      },
      {
        name: "Python",
        knwl: 30
      }
      // {
      //   name: "",
      //   knwl: 0
      // },
    ]);
    const experiences = ref<Experience[]>([
      {
        name: "Kognity",
        logo: "kognity.png",
        role: "Senior Software Engineer",
        description: "",
        join: "2020-12-01",
        leave: null
      },
      {
        name: "Genius Sports",
        logo: "genius-sports.png",
        role: "Software Engineer",
        description:
          "Responsible for the development of internal tools for bet monitoring.",
        join: "2019-04-14",
        leave: "2020-11-30"
      },
      {
        name: "Igarapé Institute",
        logo: "igarape_en_block.png",
        role: "Software Developer",
        description:
          "Responsible for the development of web, desktop and mobile applications, data visualizations and interfaces for internal tools.",
        join: "2016-04-01",
        leave: "2019-04-01"
      },
      {
        name: "FGV DAPP",
        logo: "fgv-dapp.png",
        role: "Fullstack Developer",
        description:
          "Responsible for the development of interfaces and tools for data processing, server management and maintenance.",
        join: "2014-07-01",
        leave: "2016-02-01"
      },
      {
        name: "Piloti",
        logo: "piloti.png",
        role: "Fullstack Developer",
        description:
          "Responsible for the development of interfaces and systems using the Symfony framework. ",
        join: "2013-03-01",
        leave: "2014-06-01"
      }
      // {
      //   name: "",
      //   logo: "",
      //   role: "",
      //   description: "",
      //   join: "",
      //   leave: ""
      // }
    ]);
    const yearsOfExperience = formatDistanceToNowStrict(new Date("2010-03-01"));

    function filterSocialsBy<P extends keyof Social, V>(
      property: P,
      value: string | boolean | [string, string]
    ) {
      return socials.value.filter(item => item[property] === value);
    }

    function mouseOverOutSocial(event: Event, target: Social) {
      if (!isMobile()) {
        if (event.type === "mouseover") {
          target.isHovered = true;
        } else if (event.type === "mouseout") {
          target.isHovered = false;
        }
      }
    }

    return {
      socials,
      skills,
      experiences,
      filterSocialsBy,
      mouseOverOutSocial,
      myAge,
      isMobile,
      yearsOfExperience
    };
  }
});

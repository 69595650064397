
import { defineComponent, ref, computed, onMounted } from "vue";
import { randomIntFromInterval } from "@/utils";

export default defineComponent({
  name: "SkillBar",
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      default: 0,
      validator: (value: number) => {
        return value >= 0 && value <= 100 && Number.isInteger(value);
      }
    }
  },
  setup(props) {
    const color = ref("");
    const titleStyles = computed(() => ({
      // backgroundColor: `${color.value}`
      backgroundColor: "#92a4ab"
    }));
    const barStyles = computed(() => ({
      // backgroundColor: `${color.value}`,
      backgroundColor: "#92a4ab",
      width: `${props.value}%`
    }));

    function getBgColor() {
      const r = randomIntFromInterval(0, 255);
      const g = randomIntFromInterval(0, 255);
      const b = randomIntFromInterval(0, 255);
      return `rgb(${r},${g},${b})`;
    }

    onMounted(() => {
      color.value = getBgColor();
    });

    return { color, titleStyles, barStyles };
  },
});


import { defineComponent } from "vue";
import { isValid, format } from "date-fns";
import { jobDuration } from "@/utils";

export default defineComponent({
  name: "ExperienceItem",
  props: {
    logo: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    join: {
      type: String,
      required: true,
      validator: (value: string) => {
        return isValid(new Date(value));
      },
    },
    leave: {
      type: String,
      validator: (value: null | string) => {
        return value === null || isValid(new Date(value));
      },
    },
  },
  setup(props) {
    const start = format(new Date(props.join), "MMM yyyy");
    const end = props.leave
      ? format(new Date(props.leave), "MMM yyyy")
      : "Current";
    const duration = jobDuration(
      props.join.split("-").map(v => parseInt(v)) as [number, number, number],
      !props.leave
        ? null
        : (props.leave.split("-").map(v => parseInt(v)) as [
            number,
            number,
            number
          ])
    );
    const durationText = duration ? `(${duration})` : "";

    return { start, end, durationText };
  },
});
